exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bday-base-bday-layout-jsx": () => import("./../../../src/pages/bday/base/bday_layout.jsx" /* webpackChunkName: "component---src-pages-bday-base-bday-layout-jsx" */),
  "component---src-pages-bday-sehul-2020-base-jsx": () => import("./../../../src/pages/bday/sehul/2020/base.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2020-base-jsx" */),
  "component---src-pages-bday-sehul-2020-index-jsx": () => import("./../../../src/pages/bday/sehul/2020/index.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2020-index-jsx" */),
  "component---src-pages-bday-sehul-2020-inst-jsx": () => import("./../../../src/pages/bday/sehul/2020/inst.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2020-inst-jsx" */),
  "component---src-pages-bday-sehul-2020-puzzle-1-jsx": () => import("./../../../src/pages/bday/sehul/2020/puzzle/1.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2020-puzzle-1-jsx" */),
  "component---src-pages-bday-sehul-2020-puzzle-2-jsx": () => import("./../../../src/pages/bday/sehul/2020/puzzle/2.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2020-puzzle-2-jsx" */),
  "component---src-pages-bday-sehul-2020-puzzle-3-jsx": () => import("./../../../src/pages/bday/sehul/2020/puzzle/3.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2020-puzzle-3-jsx" */),
  "component---src-pages-bday-sehul-2020-puzzle-4-jsx": () => import("./../../../src/pages/bday/sehul/2020/puzzle/4.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2020-puzzle-4-jsx" */),
  "component---src-pages-bday-sehul-2020-puzzle-small-jsx": () => import("./../../../src/pages/bday/sehul/2020/puzzle/small.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2020-puzzle-small-jsx" */),
  "component---src-pages-bday-sehul-2020-puzzle-winner-jsx": () => import("./../../../src/pages/bday/sehul/2020/puzzle/winner.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2020-puzzle-winner-jsx" */),
  "component---src-pages-bday-sehul-2020-puzzle-wrong-jsx": () => import("./../../../src/pages/bday/sehul/2020/puzzle/wrong.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2020-puzzle-wrong-jsx" */),
  "component---src-pages-bday-sehul-2021-base-jsx": () => import("./../../../src/pages/bday/sehul/2021/base.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2021-base-jsx" */),
  "component---src-pages-bday-sehul-2021-index-jsx": () => import("./../../../src/pages/bday/sehul/2021/index.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2021-index-jsx" */),
  "component---src-pages-bday-sehul-2021-inst-jsx": () => import("./../../../src/pages/bday/sehul/2021/inst.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2021-inst-jsx" */),
  "component---src-pages-bday-sehul-2021-puzzle-1-jsx": () => import("./../../../src/pages/bday/sehul/2021/puzzle/1.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2021-puzzle-1-jsx" */),
  "component---src-pages-bday-sehul-2021-puzzle-1-win-jsx": () => import("./../../../src/pages/bday/sehul/2021/puzzle/1_win.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2021-puzzle-1-win-jsx" */),
  "component---src-pages-bday-sehul-2021-puzzle-2-jsx": () => import("./../../../src/pages/bday/sehul/2021/puzzle/2.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2021-puzzle-2-jsx" */),
  "component---src-pages-bday-sehul-2021-puzzle-2-win-jsx": () => import("./../../../src/pages/bday/sehul/2021/puzzle/2_win.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2021-puzzle-2-win-jsx" */),
  "component---src-pages-bday-sehul-2021-puzzle-3-jsx": () => import("./../../../src/pages/bday/sehul/2021/puzzle/3.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2021-puzzle-3-jsx" */),
  "component---src-pages-bday-sehul-2021-puzzle-3-win-jsx": () => import("./../../../src/pages/bday/sehul/2021/puzzle/3_win.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2021-puzzle-3-win-jsx" */),
  "component---src-pages-bday-sehul-2021-puzzle-wrong-jsx": () => import("./../../../src/pages/bday/sehul/2021/puzzle/wrong.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2021-puzzle-wrong-jsx" */),
  "component---src-pages-bday-sehul-2022-base-jsx": () => import("./../../../src/pages/bday/sehul/2022/base.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2022-base-jsx" */),
  "component---src-pages-bday-sehul-2022-index-jsx": () => import("./../../../src/pages/bday/sehul/2022/index.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2022-index-jsx" */),
  "component---src-pages-bday-sehul-2022-inst-jsx": () => import("./../../../src/pages/bday/sehul/2022/inst.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2022-inst-jsx" */),
  "component---src-pages-bday-sehul-2022-puzzle-1-jsx": () => import("./../../../src/pages/bday/sehul/2022/puzzle/1.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2022-puzzle-1-jsx" */),
  "component---src-pages-bday-sehul-2022-puzzle-1-win-jsx": () => import("./../../../src/pages/bday/sehul/2022/puzzle/1_win.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2022-puzzle-1-win-jsx" */),
  "component---src-pages-bday-sehul-2022-puzzle-2-jsx": () => import("./../../../src/pages/bday/sehul/2022/puzzle/2.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2022-puzzle-2-jsx" */),
  "component---src-pages-bday-sehul-2022-puzzle-2-win-jsx": () => import("./../../../src/pages/bday/sehul/2022/puzzle/2_win.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2022-puzzle-2-win-jsx" */),
  "component---src-pages-bday-sehul-2022-puzzle-3-jsx": () => import("./../../../src/pages/bday/sehul/2022/puzzle/3.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2022-puzzle-3-jsx" */),
  "component---src-pages-bday-sehul-2022-puzzle-3-win-jsx": () => import("./../../../src/pages/bday/sehul/2022/puzzle/3_win.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2022-puzzle-3-win-jsx" */),
  "component---src-pages-bday-sehul-2022-puzzle-4-jsx": () => import("./../../../src/pages/bday/sehul/2022/puzzle/4.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2022-puzzle-4-jsx" */),
  "component---src-pages-bday-sehul-2022-puzzle-4-win-jsx": () => import("./../../../src/pages/bday/sehul/2022/puzzle/4_win.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2022-puzzle-4-win-jsx" */),
  "component---src-pages-bday-sehul-2022-puzzle-5-jsx": () => import("./../../../src/pages/bday/sehul/2022/puzzle/5.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2022-puzzle-5-jsx" */),
  "component---src-pages-bday-sehul-2022-puzzle-5-win-jsx": () => import("./../../../src/pages/bday/sehul/2022/puzzle/5_win.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2022-puzzle-5-win-jsx" */),
  "component---src-pages-bday-sehul-2022-puzzle-wrong-jsx": () => import("./../../../src/pages/bday/sehul/2022/puzzle/wrong.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2022-puzzle-wrong-jsx" */),
  "component---src-pages-bday-sehul-2023-base-jsx": () => import("./../../../src/pages/bday/sehul/2023/base.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2023-base-jsx" */),
  "component---src-pages-bday-sehul-2023-constants-jsx": () => import("./../../../src/pages/bday/sehul/2023/constants.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2023-constants-jsx" */),
  "component---src-pages-bday-sehul-2023-index-jsx": () => import("./../../../src/pages/bday/sehul/2023/index.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2023-index-jsx" */),
  "component---src-pages-bday-sehul-2023-puzzle-1-jsx": () => import("./../../../src/pages/bday/sehul/2023/puzzle/1.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2023-puzzle-1-jsx" */),
  "component---src-pages-bday-sehul-2023-puzzle-1-win-jsx": () => import("./../../../src/pages/bday/sehul/2023/puzzle/1_win.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2023-puzzle-1-win-jsx" */),
  "component---src-pages-bday-sehul-2023-puzzle-2-jsx": () => import("./../../../src/pages/bday/sehul/2023/puzzle/2.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2023-puzzle-2-jsx" */),
  "component---src-pages-bday-sehul-2023-puzzle-2-win-jsx": () => import("./../../../src/pages/bday/sehul/2023/puzzle/2_win.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2023-puzzle-2-win-jsx" */),
  "component---src-pages-bday-sehul-2023-puzzle-3-jsx": () => import("./../../../src/pages/bday/sehul/2023/puzzle/3.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2023-puzzle-3-jsx" */),
  "component---src-pages-bday-sehul-2023-puzzle-3-win-jsx": () => import("./../../../src/pages/bday/sehul/2023/puzzle/3_win.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2023-puzzle-3-win-jsx" */),
  "component---src-pages-bday-sehul-2023-puzzle-4-jsx": () => import("./../../../src/pages/bday/sehul/2023/puzzle/4.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2023-puzzle-4-jsx" */),
  "component---src-pages-bday-sehul-2023-puzzle-4-win-jsx": () => import("./../../../src/pages/bday/sehul/2023/puzzle/4_win.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2023-puzzle-4-win-jsx" */),
  "component---src-pages-bday-sehul-2023-puzzle-5-jsx": () => import("./../../../src/pages/bday/sehul/2023/puzzle/5.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2023-puzzle-5-jsx" */),
  "component---src-pages-bday-sehul-2023-puzzle-5-win-jsx": () => import("./../../../src/pages/bday/sehul/2023/puzzle/5_win.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2023-puzzle-5-win-jsx" */),
  "component---src-pages-bday-sehul-2023-puzzle-wrong-jsx": () => import("./../../../src/pages/bday/sehul/2023/puzzle/wrong.jsx" /* webpackChunkName: "component---src-pages-bday-sehul-2023-puzzle-wrong-jsx" */),
  "component---src-pages-bday-vyom-2021-base-jsx": () => import("./../../../src/pages/bday/vyom/2021/base.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2021-base-jsx" */),
  "component---src-pages-bday-vyom-2021-index-jsx": () => import("./../../../src/pages/bday/vyom/2021/index.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2021-index-jsx" */),
  "component---src-pages-bday-vyom-2021-puzzle-1-jsx": () => import("./../../../src/pages/bday/vyom/2021/puzzle/1.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2021-puzzle-1-jsx" */),
  "component---src-pages-bday-vyom-2021-puzzle-2-jsx": () => import("./../../../src/pages/bday/vyom/2021/puzzle/2.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2021-puzzle-2-jsx" */),
  "component---src-pages-bday-vyom-2021-puzzle-3-jsx": () => import("./../../../src/pages/bday/vyom/2021/puzzle/3.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2021-puzzle-3-jsx" */),
  "component---src-pages-bday-vyom-2021-puzzle-winner-jsx": () => import("./../../../src/pages/bday/vyom/2021/puzzle/winner.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2021-puzzle-winner-jsx" */),
  "component---src-pages-bday-vyom-2021-puzzle-wrong-jsx": () => import("./../../../src/pages/bday/vyom/2021/puzzle/wrong.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2021-puzzle-wrong-jsx" */),
  "component---src-pages-bday-vyom-2022-base-jsx": () => import("./../../../src/pages/bday/vyom/2022/base.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2022-base-jsx" */),
  "component---src-pages-bday-vyom-2022-index-jsx": () => import("./../../../src/pages/bday/vyom/2022/index.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2022-index-jsx" */),
  "component---src-pages-bday-vyom-2022-puzzle-1-jsx": () => import("./../../../src/pages/bday/vyom/2022/puzzle/1.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2022-puzzle-1-jsx" */),
  "component---src-pages-bday-vyom-2022-puzzle-1-prize-jsx": () => import("./../../../src/pages/bday/vyom/2022/puzzle/1_prize.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2022-puzzle-1-prize-jsx" */),
  "component---src-pages-bday-vyom-2022-puzzle-2-jsx": () => import("./../../../src/pages/bday/vyom/2022/puzzle/2.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2022-puzzle-2-jsx" */),
  "component---src-pages-bday-vyom-2022-puzzle-2-prize-jsx": () => import("./../../../src/pages/bday/vyom/2022/puzzle/2_prize.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2022-puzzle-2-prize-jsx" */),
  "component---src-pages-bday-vyom-2022-puzzle-3-jsx": () => import("./../../../src/pages/bday/vyom/2022/puzzle/3.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2022-puzzle-3-jsx" */),
  "component---src-pages-bday-vyom-2022-puzzle-3-prize-jsx": () => import("./../../../src/pages/bday/vyom/2022/puzzle/3_prize.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2022-puzzle-3-prize-jsx" */),
  "component---src-pages-bday-vyom-2022-puzzle-share-jsx": () => import("./../../../src/pages/bday/vyom/2022/puzzle/share.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2022-puzzle-share-jsx" */),
  "component---src-pages-bday-vyom-2022-puzzle-winner-jsx": () => import("./../../../src/pages/bday/vyom/2022/puzzle/winner.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2022-puzzle-winner-jsx" */),
  "component---src-pages-bday-vyom-2022-puzzle-wrong-jsx": () => import("./../../../src/pages/bday/vyom/2022/puzzle/wrong.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2022-puzzle-wrong-jsx" */),
  "component---src-pages-bday-vyom-2023-base-jsx": () => import("./../../../src/pages/bday/vyom/2023/base.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2023-base-jsx" */),
  "component---src-pages-bday-vyom-2023-constants-jsx": () => import("./../../../src/pages/bday/vyom/2023/constants.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2023-constants-jsx" */),
  "component---src-pages-bday-vyom-2023-index-jsx": () => import("./../../../src/pages/bday/vyom/2023/index.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2023-index-jsx" */),
  "component---src-pages-bday-vyom-2023-puzzle-1-jsx": () => import("./../../../src/pages/bday/vyom/2023/puzzle/1.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2023-puzzle-1-jsx" */),
  "component---src-pages-bday-vyom-2023-puzzle-1-prize-jsx": () => import("./../../../src/pages/bday/vyom/2023/puzzle/1_prize.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2023-puzzle-1-prize-jsx" */),
  "component---src-pages-bday-vyom-2023-puzzle-2-jsx": () => import("./../../../src/pages/bday/vyom/2023/puzzle/2.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2023-puzzle-2-jsx" */),
  "component---src-pages-bday-vyom-2023-puzzle-2-prize-jsx": () => import("./../../../src/pages/bday/vyom/2023/puzzle/2_prize.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2023-puzzle-2-prize-jsx" */),
  "component---src-pages-bday-vyom-2023-puzzle-3-jsx": () => import("./../../../src/pages/bday/vyom/2023/puzzle/3.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2023-puzzle-3-jsx" */),
  "component---src-pages-bday-vyom-2023-puzzle-3-prize-jsx": () => import("./../../../src/pages/bday/vyom/2023/puzzle/3_prize.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2023-puzzle-3-prize-jsx" */),
  "component---src-pages-bday-vyom-2023-puzzle-share-jsx": () => import("./../../../src/pages/bday/vyom/2023/puzzle/share.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2023-puzzle-share-jsx" */),
  "component---src-pages-bday-vyom-2023-puzzle-winner-jsx": () => import("./../../../src/pages/bday/vyom/2023/puzzle/winner.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2023-puzzle-winner-jsx" */),
  "component---src-pages-bday-vyom-2023-puzzle-wrong-jsx": () => import("./../../../src/pages/bday/vyom/2023/puzzle/wrong.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2023-puzzle-wrong-jsx" */),
  "component---src-pages-bday-vyom-2024-base-jsx": () => import("./../../../src/pages/bday/vyom/2024/base.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2024-base-jsx" */),
  "component---src-pages-bday-vyom-2024-constants-jsx": () => import("./../../../src/pages/bday/vyom/2024/constants.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2024-constants-jsx" */),
  "component---src-pages-bday-vyom-2024-index-jsx": () => import("./../../../src/pages/bday/vyom/2024/index.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2024-index-jsx" */),
  "component---src-pages-bday-vyom-2024-puzzle-1-jsx": () => import("./../../../src/pages/bday/vyom/2024/puzzle/1.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2024-puzzle-1-jsx" */),
  "component---src-pages-bday-vyom-2024-puzzle-1-win-jsx": () => import("./../../../src/pages/bday/vyom/2024/puzzle/1_win.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2024-puzzle-1-win-jsx" */),
  "component---src-pages-bday-vyom-2024-puzzle-2-jsx": () => import("./../../../src/pages/bday/vyom/2024/puzzle/2.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2024-puzzle-2-jsx" */),
  "component---src-pages-bday-vyom-2024-puzzle-2-win-jsx": () => import("./../../../src/pages/bday/vyom/2024/puzzle/2_win.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2024-puzzle-2-win-jsx" */),
  "component---src-pages-bday-vyom-2024-puzzle-3-jsx": () => import("./../../../src/pages/bday/vyom/2024/puzzle/3.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2024-puzzle-3-jsx" */),
  "component---src-pages-bday-vyom-2024-puzzle-3-win-jsx": () => import("./../../../src/pages/bday/vyom/2024/puzzle/3_win.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2024-puzzle-3-win-jsx" */),
  "component---src-pages-bday-vyom-2024-puzzle-4-jsx": () => import("./../../../src/pages/bday/vyom/2024/puzzle/4.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2024-puzzle-4-jsx" */),
  "component---src-pages-bday-vyom-2024-puzzle-4-win-jsx": () => import("./../../../src/pages/bday/vyom/2024/puzzle/4_win.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2024-puzzle-4-win-jsx" */),
  "component---src-pages-bday-vyom-2024-puzzle-5-jsx": () => import("./../../../src/pages/bday/vyom/2024/puzzle/5.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2024-puzzle-5-jsx" */),
  "component---src-pages-bday-vyom-2024-puzzle-5-win-jsx": () => import("./../../../src/pages/bday/vyom/2024/puzzle/5_win.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2024-puzzle-5-win-jsx" */),
  "component---src-pages-bday-vyom-2024-puzzle-wrong-jsx": () => import("./../../../src/pages/bday/vyom/2024/puzzle/wrong.jsx" /* webpackChunkName: "component---src-pages-bday-vyom-2024-puzzle-wrong-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

